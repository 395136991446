.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

.right-container {
  overflow-x: auto;
  height: 100% !important;
  width: 85%;
  position: fixed;
  left: 15%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  padding: 1rem 1rem 0rem 1rem;
  justify-content: space-between;
}

.navigationBar {
  height: 100%;
  width: 15% !important;
  padding: 0px;
  /* position: fixed; */
}

.expanded-nav {
  display: block;
}

.collapsed-nav{
  display: none;
}

.sidenav---navtext---1AE_f{
  font-size: 16px !important;
}

/* .logo {
  display: block;
}

.logo_collapsed{
  display: none;
} */
.navigation-item{
  align-self: center !important;
}

@media screen and (max-width: 1250px) {
  .expanded-nav {
    display: none;
  }

  .collapsed-nav{
    display: block;
    display: flex;
    flex-direction: column;
  }

  .navigation-item{
    align-self: center;
  }

  /* .logo {
    display: none;
  }
  
  .logo_collapsed{
    display: block;
    width: -webkit-fill-available;
  } */
}

.footerContainer {
  width: 100%;
  height: 15%;
  padding-top: 2%;
  /* top: 85%; */
  /* position: fixed; */
  bottom: 0%;
}

.navbar {
  height: 100%;
  width: 15%;
  align-content: flex-start;
  background-color: #324B63 !important;
  min-width: 15% !important;
}

.navigationOption {
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  align-content: center;
  justify-content: space-between;
  ;
  /* align-items: stretch; */
  height: 85%
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}