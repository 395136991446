.navigationBar {
  color: white;
  background-color: #2A3F54;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.logo {
  margin: auto;
  margin-bottom: 20px;
  /* margin-top: 15px; */
  width: 79%;
}

.headerContainer {
  padding-top: 10px;
  height: 50px;
}

.login {
  margin-top: 100% !important;
  /* bottom: 15%; */
}

.footer {
  position: fixed;
  bottom: 0;
  text-align: left;
}

.button {
  width: 100%;
  margin: 5px 0px 5px 0px;
  padding: 14px;
  background-color: transparent;
  color: white;
  border: none;

}

.navigation-ul {
  width: 100%;
}

.navigation-li {
  padding: 0px;
  margin: 10px 0px 10px 0px;
}

.navigation-li a {
  color: white;
  padding: 10px;
  font-size: 20px;
}

.navigation-li:focus,
.navigation-li:hover {
  background-color: white;
}

.navigation-li .nav-link:hover {
  color: black !important;
}

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  /* font-family: sans-serif; */
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border: 2px solid #2A3F54;
}

.styled-table thead tr {
  background-color: #2A3F54;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

/* 
.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
} */

.styled-table tbody tr.active-row {
  font-weight: normal;
  /* font-family: cursive; */
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selected {
  background-color: white;
  color: black !important;
}

.MuiTableRow-hover:hover {
  background-color: #73bcfa !important;
}

.MuiFormControl-root,
.MuiInput-root {
  width: 100% !important
}

.MuiFormControl-root.MuiTextField-root[class*='MTableToolbar-searchField'] {
  width: 30% !important;
}

.MuiAutocomplete-root {
  width: 90% !important
}

.MuiFormLabel-root {
  font-weight: bolder !important;
  font-style: italic;
}

.MuiTableCell-root.MuiTableCell-body {
  text-align: center;
}

.CreateModelCard {
  width: 80% !important;
}

.selected-cell {
  background-color: #649fe3;
}

.MuiDataGrid-columnHeaders {
  background-color: rgb(51, 77, 102);
  color: white;
}

.MuiDataGrid-row:nth-child(even) {
  background-color: #E4ECF5
}

.MuiDataGrid-cell:hover:not([data-colindex="0"]) {
  background-color: #acccf1;
}

tbody tr:hover{
  background-color: #acccf1 !important;
  cursor: pointer;
  transform: translateY(-5px);
}