
  .image-overlay {
    position: relative;
  }
  
  .overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(245, 244, 244, 0.5);
    color: black;
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    margin-top: 5px;
    width: 65%;
    height: 80%;
  }
